<template>
  <div class="base-template page-wrapper">
    <router-view></router-view>
    <OrganismMenuBar />
    <OrganismSidebar>
      <div class="menu">
        <div>
          <router-link active-class="active" class="sidebar-link" tag="a" :to="{name: 'Home'}">Home</router-link>
        </div>
        <OrganismProjectLinks />
      </div>
      <div class="social-links">
        <a :href="instagramUrl"><AtomIcon icon="instagram" :size="24" color="#000"/></a>
        <a :href="linkedinUrl"><AtomIcon icon="linkedin-squared" :size="24" color="#000"/></a>
        <a :href="facebookUrl"><AtomIcon icon="facebook-squared" :size="24" color="#000"/></a>
      </div>
    </OrganismSidebar>
    <a class="footer-bright" href="https://brightspaces.tech/" target="_blank"> Created by ©BrightSpaces</a>
  </div>
</template>

<script>
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import OrganismMenuBar from "../components/organisms/OrganismMenuBar";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import OrganismProjectLinks from "../components/organisms/OrganismProjectsLinks";

export default {
  name: "BaseTemplate",
  components: {OrganismProjectLinks, AtomIcon, OrganismMenuBar, OrganismSidebar},
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    instagramUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.facebookUrl;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    }
  },
  beforeMount() {
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.LOAD_LANDLORD_DETAILS));
  }
}
</script>

<style lang="scss" scoped>
  .project-template {
    display: block;
  }
</style>