var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismMenuBar"),
      _c("OrganismSidebar", [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "sidebar-link",
                    attrs: {
                      "active-class": "active",
                      tag: "a",
                      to: { name: "Home" }
                    }
                  },
                  [_vm._v("Home")]
                )
              ],
              1
            ),
            _c("OrganismProjectLinks")
          ],
          1
        ),
        _c("div", { staticClass: "social-links" }, [
          _c(
            "a",
            { attrs: { href: _vm.instagramUrl } },
            [
              _c("AtomIcon", {
                attrs: { icon: "instagram", size: 24, color: "#000" }
              })
            ],
            1
          ),
          _c(
            "a",
            { attrs: { href: _vm.linkedinUrl } },
            [
              _c("AtomIcon", {
                attrs: { icon: "linkedin-squared", size: 24, color: "#000" }
              })
            ],
            1
          ),
          _c(
            "a",
            { attrs: { href: _vm.facebookUrl } },
            [
              _c("AtomIcon", {
                attrs: { icon: "facebook-squared", size: 24, color: "#000" }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "a",
        {
          staticClass: "footer-bright",
          attrs: { href: "https://brightspaces.tech/", target: "_blank" }
        },
        [_vm._v(" Created by ©BrightSpaces")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }